import * as React from 'react';
import Layout from '../component/layout'
import { Link }  from 'gatsby';
import _ from 'lodash';
import ReactMarkdown from 'react-markdown'
import fetchGSheet2 from '../component/fetchGSheet'

const QueerResources = () => {
    const [entries, setEntries] = React.useState(undefined)
    const [queerResources, setQueerResources] = React.useState([])
    React.useEffect(() => {
        fetchGSheet2(`'Queer Resources'!A:B`, setEntries)
        if (entries === undefined) return;

        let groupedEntries = _.mapValues(_.groupBy(entries, 'type'), list => list.map(resource => _.omit(resource, 'type')))
        groupedEntries = Object.entries(groupedEntries).map((entry) => {
            return {title: entry[0], entries: entry[1]}
        })
        setQueerResources(groupedEntries)
    }, [entries])

    const CUSTOM_COMPONENT_RENDER = {
        a: ({node, ...props}) => <a className='font-sylexiad' {...props} target='_blank' rel='noopener noreferrer'/>,
        p: ({node, ...props}) => <p className='font-sylexiad text-2xl' {...props} />,
        ul: ({node, ...props}) => <p className='statement-list text-xl' {...props} />
    }

    return (
        <Layout title={'Queer Resources | YouYou'}
                keywords={['queer resources', 'singapore', 'lgbtq affirming']}>
            <div className='grid grid-rows-auto grid-cols-1 md:grid-cols-2 gap-4 justify-center items-stretch m-3 md:m-10'>
                {/* row */}
                <div className='col-span-1 md:col-span-2 font-sylexiad'>
                    <h1 className='title'>Queer Resources in Singapore</h1>
                    <p className='subtitle text-2xl whitespace-pre-line'>Other Resources
                        : <Link to='/mental-health' className='underline'>{'Mental Health'}</Link> | <Link to='/christianity-and-queerness' className='underline'>{'Christianity & Queerness'}</Link> | <Link to='/islam-and-queerness' className='underline'>{'Islam & Queerness'}</Link>
                    </p>
                    <br />
                    <p className='text text-base'>Please <a href='mailto:theskinnyhobbit@gmail.com?subject=SG%20Queer%20Resources' className='underline'>contact our friendly webmaster</a> if you have a resource to add to the list! :)</p>
                </div>
            </div>
            
            {/* row */}
            <div className='grid grid-rows-auto grid-cols-1 md:grid-cols-3 gap-4 justify-center items-stretch m-3 md:m-10'>
                {/* row */}
                <div className='col-span-1 md:col-span-3 font-sylexiad'>
                    <h2 className='title'>Openly LGBTQIA+ Affirming Mental Health Resources</h2>
                </div>
            </div>

            {/* row */}
            {queerResources.map(r => {
                return (
                <div key={r.title} className='col-span-1 md:col-span-3 grid grid-cols-1 gap-4 justify-center items-stretch md:grid-cols-3 m-3 md:m-10 border-b md:pb-8'>
                    <div className='col-span-1 font-sylexiad pt-6'>
                        <h3 className='title'>{r.title}</h3>
                    </div>
                    <div className='col-span-1 md:col-span-2 font-sylexiad text-2xl divide-y divide-gray-300'>
                        {r.entries.map((e, i) => {
                            return (
                                <div key={i} className='py-6'>
                                    <ReactMarkdown children={e.content} components={CUSTOM_COMPONENT_RENDER} />
                                </div>
                            )
                        })}
                    </div>
                </div>
                )
            })}
        </Layout>
    )
}

export default QueerResources;